<template lang="pug">
.om-ai-success
  wizard-top.mb-4(:show-progress="false")
  .container
    .d-flex.justify-content-center
      om-monks.mr-5(monk="monkie-balloon" style="max-width: 280px")
      .d-flex.flex-column.align-items-start.justify-content-center
        h2(style="max-width: 41rem") {{ $t('onboarding.aiSuccess.title') }}
        om-body-text.my-5(bt400md) {{ $t('onboarding.aiSuccess.text') }}
        om-button.ai-success-continue(primary @click="navigateToDashboard") {{ $t('continue') }}
</template>
<script>
  import WizardTop from '@/components/Wizard/Top.vue';

  export default {
    components: {
      WizardTop,
    },
    methods: {
      navigateToDashboard() {
        this.$router.push({ name: 'dashboard' });
      },
    },
  };
</script>
